var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"fill-height"},[_c('Loading',{attrs:{"image":2,"loading":_vm.loading}}),_c('div',{staticClass:"Drawing",class:_vm.loading ? 'fill-height' : 'simulator-container',attrs:{"id":"Drawing"}},[(1 < 1)?_c('MobileAlert'):_vm._e(),(!_vm.loading)?_c('NavbarDrawing'):_vm._e(),(!_vm.loading)?_c('multipane',{staticClass:"resizer-content",attrs:{"layout":"vertical"}},[_c('div',{staticClass:"multipane-content"},[_c('SideNavSimulator')],1),_c('multipane-resizer'),_c('div',{staticClass:"multipane-content center"},[_c('div',{staticClass:"container-canvas"},[(!_vm.showMiniCanvas)?_c('v-stage',{class:_vm.mode + ' ' + _vm.zoomAction,attrs:{"config":_vm.configKonva},on:{"wheel":_vm.ZOOM}},[_c('v-layer',[_c('v-rect',{attrs:{"config":{
                  token: 'main',
                  width: _vm.dimensions.width,
                  height: _vm.dimensions.height,
                  fill: _vm.cell.fill,
                  id: 'mainRect'
                }},on:{"mousedown":_vm.mouseDownEvent,"mousemove":_vm.MudarTamanhoDesenho,"mouseup":_vm.FimDesenho}}),_vm._l((_vm.grid),function(item,indice){return _c('v-rect',{key:indice,attrs:{"config":{ ...item, visible: _vm.gridVisible }},on:{"mousedown":_vm.mouseDownEvent,"dragmove":_vm.handleDrag,"mousemove":_vm.MudarTamanhoDesenho,"mouseup":_vm.FimDesenho}})}),_vm._l((_vm.triangleGeometry),function(geometry){return _c('v-regular-polygon',{key:geometry.token,attrs:{"config":{
                  ...geometry,
                  draggable:
                    _vm.currentGeometry.x + _vm.currentGeometry.width >=
                      _vm.dimensions.width || _vm.currentGeometry.x <= 0
                      ? false
                      : _vm.currentGeometry.y + _vm.currentGeometry.height >=
                          _vm.dimensions.height || _vm.currentGeometry.y <= 0
                      ? false
                      : _vm.mode === 'transform' && _vm.mode !== 'handle'
                      ? true
                      : false,
                  fill:
                    geometry.geometricFill.properties.image == ''
                      ? geometry.fill
                      : '',
                  fillPatternImage: geometry.geometricFill.properties.image,
                  fillPatternRepeat: 'repeat',
                  fillPatternScale: {
                    x:
                      (geometry.geometricFill.properties.width /
                        _vm.dimensions.relationship.x) *
                      1.5,
                    y:
                      (geometry.geometricFill.properties.height /
                        _vm.dimensions.relationship.y) *
                      1.5
                  }
                }},on:{"transform":_vm.handleTransform,"transformstart":_vm.handleTransformStart,"dragmove":_vm.handleDrag,"mousedown":_vm.mouseDownTransformEvent,"click":function($event){return _vm.setSelectedTokenAction(geometry.token)}}})}),_vm._l((_vm.circleGeometry),function(geometry){return _c('v-circle',{key:geometry.token,attrs:{"config":{
                  ...geometry,
                  draggable:
                    _vm.currentGeometry.x + _vm.currentGeometry.width >=
                      _vm.dimensions.width || _vm.currentGeometry.x <= 0
                      ? false
                      : _vm.currentGeometry.y + _vm.currentGeometry.height >=
                          _vm.dimensions.height || _vm.currentGeometry.y <= 0
                      ? false
                      : _vm.mode === 'transform' && _vm.mode !== 'handle'
                      ? true
                      : false,
                  fill:
                    geometry.geometricFill.properties.image == ''
                      ? geometry.fill
                      : '',
                  fillPatternImage: geometry.geometricFill.properties.image,
                  fillPatternRepeat: 'repeat',
                  fillPatternScale: {
                    x:
                      (geometry.geometricFill.properties.width /
                        _vm.dimensions.relationship.x) *
                      1.5,
                    y:
                      (geometry.geometricFill.properties.height /
                        _vm.dimensions.relationship.y) *
                      1.5
                  }
                }},on:{"transform":_vm.handleTransform,"transformstart":_vm.handleTransformStart,"dragmove":_vm.handleDrag,"mousedown":_vm.mouseDownTransformEvent,"click":function($event){return _vm.setSelectedTokenAction(geometry.token)}}})}),_vm._l((_vm.blockGeometry),function(geometry){return _c('v-rect',{key:geometry.token,attrs:{"config":{
                  ...geometry,
                  draggable:
                    _vm.currentGeometry.x + _vm.currentGeometry.width >=
                      _vm.dimensions.width || _vm.currentGeometry.x <= 0
                      ? false
                      : _vm.currentGeometry.y + _vm.currentGeometry.height >=
                          _vm.dimensions.height || _vm.currentGeometry.y <= 0
                      ? false
                      : _vm.mode === 'transform' && _vm.mode !== 'handle'
                      ? true
                      : false,
                  fill:
                    geometry.geometricFill.properties.image == ''
                      ? geometry.fill
                      : '',
                  fillPatternImage: geometry.geometricFill.properties.image,
                  fillPatternRepeat: 'repeat',
                  fillPatternScale: {
                    x:
                      (geometry.geometricFill.properties.width /
                        _vm.dimensions.relationship.x) *
                      1.5,
                    y:
                      (geometry.geometricFill.properties.height /
                        _vm.dimensions.relationship.y) *
                      1.5
                  }
                }},on:{"transform":_vm.handleTransform,"transformstart":_vm.handleTransformStart,"dragmove":_vm.handleDrag,"mousedown":_vm.mouseDownTransformEvent,"click":function($event){return _vm.setSelectedTokenAction(geometry.token)}}})}),_vm._l((_vm.SourcesList),function(source){return _c('v-circle',{key:source.token,attrs:{"config":{
                  ...source,
                  draggable:
                    _vm.currentGeometry.x + _vm.currentGeometry.width >
                      _vm.dimensions.width || _vm.currentGeometry.x <= 0
                      ? false
                      : _vm.currentGeometry.y + _vm.currentGeometry.height >=
                          _vm.dimensions.height || _vm.currentGeometry.y <= 0
                      ? false
                      : _vm.mode === 'transform' && _vm.mode !== 'handle'
                      ? true
                      : false
                }},on:{"transform":_vm.handleTransform,"transformstart":_vm.handleTransformStart,"dragmove":_vm.handleDrag,"mousedown":_vm.mouseDownTransformEvent}})}),_vm._l((_vm.FluxList),function(flux){return _c('v-circle',{key:flux.token,attrs:{"config":{
                  ...flux,
                  visible: _vm.viewMode === 'flux' ? true : false,
                  draggable:
                    _vm.currentGeometry.x + _vm.currentGeometry.width >
                      _vm.dimensions.width || _vm.currentGeometry.x <= 0
                      ? false
                      : _vm.currentGeometry.y + _vm.currentGeometry.height >=
                          _vm.dimensions.height || _vm.currentGeometry.y <= 0
                      ? false
                      : _vm.mode === 'transform' && _vm.mode !== 'handle'
                      ? true
                      : false
                }},on:{"transform":_vm.handleTransform,"transformstart":_vm.handleTransformStart,"dragmove":_vm.handleDrag,"mousedown":_vm.mouseDownTransformEvent}})}),_vm._l((_vm.FluxList),function(flux){return _c('v-circle',{key:flux.token,attrs:{"config":{
                  ...flux,
                  ...flux.transmittance_normal_flux.center,
                  width: 3,
                  height: 3,
                  fill: '#FF00FF',
                  visible: _vm.viewMode === 'flux' ? true : false,
                  draggable:
                    _vm.currentGeometry.x + _vm.currentGeometry.width >
                      _vm.dimensions.width || _vm.currentGeometry.x <= 0
                      ? false
                      : _vm.currentGeometry.y + _vm.currentGeometry.height >=
                          _vm.dimensions.height || _vm.currentGeometry.y <= 0
                      ? false
                      : _vm.mode === 'transform' && _vm.mode !== 'handle'
                      ? true
                      : false
                }},on:{"transform":_vm.handleTransform,"transformstart":_vm.handleTransformStart,"dragmove":_vm.handleDrag,"mousedown":_vm.mouseDownTransformEvent}})}),_vm._l((_vm.FluxList),function(flux){return _c('v-circle',{key:flux.token,attrs:{"config":{
                  ...flux,
                  ...flux.transmittance_normal_flux.center,
                  fill: '#00FF00',
                  visible: _vm.viewMode === 'flux' ? true : false,
                  draggable:
                    _vm.currentGeometry.x + _vm.currentGeometry.width >
                      _vm.dimensions.width || _vm.currentGeometry.x <= 0
                      ? false
                      : _vm.currentGeometry.y + _vm.currentGeometry.height >=
                          _vm.dimensions.height || _vm.currentGeometry.y <= 0
                      ? false
                      : _vm.mode === 'transform' && _vm.mode !== 'handle'
                      ? true
                      : false
                }},on:{"transform":_vm.handleTransform,"transformstart":_vm.handleTransformStart,"dragmove":_vm.handleDrag,"mousedown":_vm.mouseDownTransformEvent}})}),_c('v-transformer',{ref:"transformer",attrs:{"id":"transformer"}})],2)],1):_vm._e()],1)]),_c('div',{staticClass:"multipane-content right"},[_c('RightNavSimulator')],1)],1):_vm._e(),_c('LoadingModal'),_c('PreviewSimulationModal')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }